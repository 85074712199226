import commonHelper from '@/app/utility/common.helper.utility';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import invoiceApprovalDetails from './invoiceApprovalDetails';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'invoiceApproval',
  watch: {
    currentPage: function() {
      this.getVendorInvoiceDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorInvoiceDetails();
    },
    currentPageVendor: function() {
      this.getVendorDetails();
    },
    perPageVendor: function() {
      this.currentPageVendor = 1;
      this.getVendorDetails();
    },
    selectAll: function() {
      this.checkAll();
    }
  },
  components: {
    invoiceApprovalDetails,
    DatePicker,
    HistoryRecord,
    ApprovalHistory
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      perPageVendor: commonHelper.perPageRecord,
      totalRowsVendor: null,
      currentPageVendor: 1,
      loader: false,
      showValueSetModal: false,
      vendorInvId: null,
      poNum: null,
      vendor: { value: null, text: null },
      invoiceNum: null,
      status: { value: null, text: null },
      employee: { value: null, text: null },
      pending: { value: null, text: null },
      fromDate: null,
      toDate: null,
      invoiceApprovalData: [],
      invoiceApprovalFields: [
        // {
        //   key: 'select',
        //   stickyColumn: true,
        //   variant: 'primary'
        // },
        {
          key: 'approval_status'
        },
        {
          key: 'vendor_id',
          label: 'Vendor Code'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_site_name'
        },
        {
          key: 'po_num',
          label: 'PO Number'
        },
        {
          key: 'po_status',
          label: 'PO Status'
        },
        {
          key: 'inv_name',
          label: 'Invoice Number'
        },
        {
          key: 'inv_date',
          label: 'Invoice Date'
        },
        {
          key: 'submission_date'
        },
        {
          key: 'receipt_date'
        },
        {
          key: 'receipt_num',
          label: 'Receipt Number'
        },
        {
          key: 'inv_amt',
          label: 'Invoice Amount',
          class: 'fix-tax-amt'
        },
        { key: 'currency', stickyColumn: true },
        {
          key: 'first_party_gstn',
          label: 'Company GSTN'
        },
        {
          key: 'third_party_gstn',
          label: 'Third Party GSTN'
        },
        {
          key: 'status_code',
          label: 'Status'
        },
        {
          key: 'status_msg',
          label: 'Error Message'
        }
      ],
      vendorSearchData: [],
      vendorSearchFields: [
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_code'
        },
        {
          key: 'pan_number',
          label: 'PAN No.'
        }
      ],
      vendorName: '',
      vendorCode: '',
      panNumber: '',
      invoiceRowData: null,
      showInvoiceDetailsModal: false,
      showVendorSearchModal: false,
      wfMenuId: null,
      eventId: null,
      userId: this.$store.state.auth.userId,
      menuId: this.$store.state.shared.menuId,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      showHistoryBtn: false,
      showHistory: false,
      historyType: null,
      invoiceDetails: null,
      selectedDate: null,
      totalPayload: null,
      dateFilterValue: 'Invoice Date',
      dateFilter: ['Invoice Date', 'Reciept Date', 'Submission Date'],
      sortFlag: {
        invDate: true,
        subDate: false,
        recDate: false
      },
      arrowDate: false,
      arrowSubDate: false,
      arrowRecDate: false,
      type: null,
      assigned: { value: null, text: null },
      assignedValue: 'Assigned',
      assignedFilter: ['Assigned', 'UnAssigned', 'All'],
      selectAll: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.downloadCustomExcel();
        }
      }
    });
  },
  methods: {
    sortFunc(item) {
      if (item === 'Invoice Date Low to High') {
        this.sortFlag = {
          invDate: true,
          subDate: false,
          recDate: false
        };
        this.arrowDate = true;
        this.orderBy = 'ASC';
        this.orderName = 'INV_DATE';
      } else if (item === 'Invoice Date High to Low') {
        this.sortFlag = {
          invDate: true,
          subDate: false,
          recDate: false
        };
        this.arrowDate = false;
        this.orderBy = 'DESC';
        this.orderName = 'INV_DATE';
      } else if (item === 'Submission Date Low to High') {
        this.sortFlag = {
          invDate: false,
          subDate: true,
          recDate: false
        };
        this.arrowSubDate = true;
        this.orderBy = 'ASC';
        this.orderName = 'SUBMISSION_DATE';
      } else if (item === 'Submission Date High to Low') {
        this.sortFlag = {
          invDate: false,
          subDate: true,
          recDate: false
        };
        this.arrowSubDate = false;
        this.orderBy = 'DESC';
        this.orderName = 'SUBMISSION_DATE';
      } else if (item === 'Receipt Date Low to High') {
        this.sortFlag = {
          invDate: false,
          subDate: false,
          recDate: true
        };
        this.arrowRecDate = true;
        this.orderBy = 'ASC';
        this.orderName = 'RECEIPT_DATE';
      } else if (item === 'Receipt Date High to Low') {
        this.sortFlag = {
          invDate: false,
          subDate: false,
          recDate: true
        };
        this.arrowRecDate = false;
        this.orderBy = 'DESC';
        this.orderName = 'RECEIPT_DATE';
      }
      this.getVendorInvoiceDetails();
    },
    closeModalDelegation() {
      this.showHistory = false;
      this.showInvoiceDetailsModal = false;
      this.clearFilters();
    },
    getVendorInvoiceDetails() {
      if (this.invoiceNum || (this.fromDate && this.toDate)) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          vendor_name: this.vendor.text ? this.vendor.text : null,
          po_num: this.poNum ? this.poNum : null,
          approval_status: this.status.value ? this.status.value : null,
          inv_num: this.invoiceNum ? this.invoiceNum : null,
          date_type: this.dateFilterValue,
          from_date: this.fromDate ? this.fromDate : null,
          to_date: this.toDate ? this.toDate : null,
          order_by: this.orderBy ? this.orderBy : 'DESC',
          order_name: this.orderName ? this.orderName : 'INV_DATE',
          user_id: this.$store.state.auth.userId,
          employee_id: this.employee.value,
          pending_with: this.pending.text
          // assigned_to: this.assigned.text,
          // allocation_type: this.assignedValue
        };
        this.totalPayload = payload;
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/getVendorInvoiceDetails', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.invoiceApprovalData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
              this.getMenuId();
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert('Please Enter Invoice Number Or From Date and To Date');
      }
    },
    downloadCustomExcel() {
      this.totalPayload._limit = this.totalRows;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getVendorInvoiceDetails', this.totalPayload)
        .then(resp => {
          this.loader = false;
          const result = resp.data.data.page;
          const customDownloadData = result.map(obj => ({
            approval_status: obj.approval_status,
            legal_entity: obj.le_name,
            vendor_name: obj.vendor_name,
            vendor_site_name: obj.vendor_site_name,
            po_number: obj.po_num,
            po_status: obj.po_status,
            invoice_name: obj.inv_name,
            invoice_date: obj.inv_date ? new Date(obj.inv_date) : null,
            submission_date: obj.submission_date
              ? new Date(obj.submission_date)
              : null,
            invoice_amount: obj.inv_amt,
            receipt_number: obj.receipt_num,
            receipt_date: obj.receipt_date ? new Date(obj.receipt_date) : null,
            currency: obj.currency,
            company_gstn: obj.first_party_gstn,
            third_party_gstn: obj.third_party_gstn,
            grn_status: obj.status_code,
            error_message: obj.status_msg
          }));
          if (customDownloadData) {
            commonHelper.downloadExcel(customDownloadData, 'invoice-approval');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getMenuId() {
      const payload = {
        event_name: this.$route.name
      };
      this.$store
        .dispatch('intWorkflow/getDynamicMenuId', payload)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data;
            if (result) {
              this.wfMenuId = result.menu_id;
              this.getEventId();
            }
          }
        });
    },
    getEventId() {
      this.$store
        .dispatch('intWorkflow/getEventId', this.wfMenuId)
        .then(response => {
          if (response.status === 200) {
            this.eventId = response.data.data;
          }
        });
    },
    rowSelected(item) {
      this.invoiceRowData = item;
      this.showHideInvoiceDetailsModal(true);
    },
    showHideInvoiceDetailsModal(flag) {
      this.showInvoiceDetailsModal = flag;
    },
    openValueSetModal(vsetCode, type) {
      this.vsetCode = vsetCode;
      this.type = type;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_APPROVAL_STATUS_VSET
      ) {
        this.status.text = item.value_meaning;
        this.status.value = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.INVOICE_EMPLOYEE_VSET
      ) {
        if (this.type === 'emp') {
          this.employee.text = item.value_code;
          this.employee.value = item.value_set_dtl_id;
        } else if (this.type === 'pending') {
          this.pending.text = item.value_code;
          this.pending.value = item.value_set_dtl_id;
          this.status.text = 'INPROCESS';
          this.status.value = 'INPROCESS';
        } else if (this.type === 'assigned') {
          this.assigned.text = item.value_code;
          this.assigned.value = item.value_set_dtl_id;
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    dateChange(ele) {
      this.selectedDate = ele;
      this.toDate = null;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.selectedDate);
    },
    clearFilters() {
      this.poNum = null;
      this.invoiceNum = null;
      this.vendor = { value: null, text: null };
      this.status = { value: null, text: null };
      this.employee = { value: null, text: null };
      this.pending = { value: null, text: null };
      this.assigned = { value: null, text: null };
      this.fromDate = null;
      this.toDate = null;
      this.dateFilterValue = 'Invoice Date';
      this.assignedValue = 'Assigned';
      this.invoiceApprovalData = [];
      this.totalRows = null;
      this.selectAll = false;
      this.currentPage = 1;
      this.showHistoryBtn = false;
    },
    checkAll() {
      this.invoiceApprovalData = this.invoiceApprovalData.map(data => {
        data.select = data.invoice_level === 'INVOICE' && this.selectAll;
        return data;
      });
    },
    formattingAmount(amount) {
      return commonHelper.formatAmountWithoutCurrency(amount);
    },
    selectedInvoiceRow(item) {
      this.vendorInvId = item.vendor_inv_id;
      this.invoiceDetails = item;
      this.showHistoryBtn = true;
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
      // if (type == 'dropdown') {
      //   this.invoiceDetails = this.invoiceRowData;
      //   this.vendorInvId = this.invoiceRowData.vendor_inv_id;
      // }
      // if (!flag) {
      //   this.clearFilters();
      // }
    },
    showHideVendorSearchModal(flag) {
      this.showVendorSearchModal = flag;
      if (flag) {
        this.getVendorDetails();
      }
    },
    rowSelectedVendor(item) {
      this.vendor.text = item.vendor_name;
      this.vendor.value = item.vendor_id;
      this.showVendorSearchModal = false;
      this.vendorName = '';
      this.vendorCode = '';
      this.panNumber = '';
      this.perPageVendor = commonHelper.perPageRecord;
      this.totalRowsVendor = null;
      this.currentPageVendor = 1;
    },
    getVendorDetails() {
      const payload = {
        _page: this.currentPageVendor - 1,
        _limit: this.perPageVendor,
        vendor_name: this.vendorName,
        vendor_code: this.vendorCode,
        pan_number: this.panNumber
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getVendorDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.vendorSearchData = resp.data.data.data.page;
            this.totalRowsVendor = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    assignVendorInvoice(flag) {
      const filterData = this.invoiceApprovalData.filter(ele => ele.select);
      if (filterData.length) {
        const assignInvDetails = filterData.map(elem => {
          return {
            vendor_inv_id: elem.vendor_inv_id,
            assign_user_id: this.$store.state.auth.userId,
            assign_flag: flag
          };
        });
        if (assignInvDetails.length) {
          const payload = {
            vendor_invoices: assignInvDetails ? assignInvDetails : null
          };
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/assignVendorInvoice', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                alert(response.data.message);
                this.getVendorInvoiceDetails();
              } else {
                alert(response.response.data.message);
              }
            })
            .catch(() => {
              alert(appStrings.autoFailedMsg);
            });
        }
      } else {
        alert('Kindly Select Any Invoice');
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.employee.value) {
        this.employee = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.status.value) {
        this.status = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.pending.value) {
        this.pending = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.assigned.value) {
        this.assigned = {
          text: null,
          value: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
